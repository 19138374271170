import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Pharms のブランドルーツを辿る",
  "date": "2021-06-25T09:01:08.000Z",
  "slug": "entry/2021/06/25/180108",
  "tags": ["medley"],
  "hero": "./2021_06_25.png",
  "heroAlt": "Pharms"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`「2020 年 9 月に調剤薬局向けのプロダクトをリリースする」`}</p>
    <p>{`この`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20209-9clinics.html"
      }}>{`プレスリリース`}</a>{`が発表されたのは、COVID-19 の感染拡大に端を発する緊急事態宣言が発令されていた 2020 年 4 月半ば。その 1 ヶ月後、私はリモートワーク下でのオンライン MTG になじめない状態のまま、調剤薬局向けプロダクトのブランディングについて役員陣や主要プロジェクトメンバーにプレゼンを行っていた。`}</p>
    <p>{`今回は当時のプレゼン資料をたどりながら`}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{`のブランド設計について説明していこうと思う。`}</p>
    <h1>{`医薬分業のルーツとは`}</h1>
    <p>{`デザイナーとして前田がメドレーに入社してから、オンライン診療や電子カルテなど、主に医療機関向けのプロダクトデザインを担当していたものの、調剤薬局のプロダクトデザインは未知の領域。ブランディングを検討する上で、薬の処方を行う医師と調剤を実施する薬剤師が分担して行う医薬分業のルーツついて調べることからはじめた。`}</p>
    <p>{`医薬分業は、毒殺を恐れたフリードリヒ 2 世が主治医の処方した薬を、毒が盛られてないか他者にチェックさせたのが始まりとされている。`}</p>
    <p>{`（参考：`}<a parentName="p" {...{
        "href": "https://www.nichiyaku.or.jp/activities/division/about.html"
      }}>{`公益社団法人 日本薬剤師会 HP ｜医薬分業とは`}</a>{`）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142428.png",
      "alt": "20210625142428.png"
    }}></img>
    <h1>{`医療プラットフォームの未来を見据えたブランド定義`}</h1>
    <p>{`次に、調剤に関する法制度や競合などの外部要因、メドレーとしてのブランド力や開発力などの内部要因について簡易な SWOT 分析を行い、調剤薬局のプロジェクトの妥当性を検証。メドレーが取り組む医療プラットフォーム事業(※)に、あらたに調剤薬局プロジェクトが加わることによる他のプロダクトとのバランスも考慮しながらブランドネーミング検討を行っていった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142452.png",
      "alt": "20210625142452.png"
    }}></img>
    <p><em parentName="p">{`※) 医療プラットフォーム事業では、患者と医療領域の業務システムを SaaS プロダクトでつなぎ、患者と医療機関双方にとって、テクノロジーの恩恵を受けることのできるプラットフォームづくりを行っている。主要サービスはクラウド診療支援システム「CLINICS」やオンライン診療・服薬指導アプリ「CLINICS」。`}</em></p>
    <p>{`メドレーのこれまでの歴史を振り返ると、プロダクト内容を明確かつ端的に表したネーミングが多く、メドレーらしさ = 「中央突破なネーミング」ということを定義し、ネーミングを検討していった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142510.png",
      "alt": "20210625142510.png"
    }}></img>
    <p>{`最終的に調剤薬局を表す「Pharmacies（ファーマシーズ）」と「Pharms（ファームス）」の 2 案に絞込み、それぞれのメリット・デメリットを整理していった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142528.png",
      "alt": "20210625142528.png"
    }}></img>
    <p>{`当時、社内では調剤薬局システム = Pharmacies と呼ばれており、その中央突破なネーミングが最有力候補であった。一方で、ブランドで体現すべきアイデンティティの欠如や、呼びづらさなどが課題として散見された。さらには医療プラットフォーム全体を見据えたブランド構築という観点から考慮すると、バランス面での課題が浮き彫りになり、それら課題をクリアにして誕生したのが Pharms（ファームス）である。`}</p>
    <h1>{`ヴィジュアル・アイデンティティの設計`}</h1>
    <p>{`ブランド名が固まれば、あとはヴィジュアル・アイデンティティを突き詰めていくのみ。視認性や可読性を考慮したフォントフェイスの検証や調剤薬局と想起させるブランドカラーの選定、またシンボルの設計などに取り掛かっていく。`}</p>
    <p>{`ブランドカラーの選定においては、なんとなく「緑」というイメージがチーム内でもあったが、より精緻化するため、薬の起源や調剤薬局本来の役割を踏まえ詳細に落とし込んでいった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142546.png",
      "alt": "20210625142546.png"
    }}></img>
    <p>{`ロゴにシンボルを含めるか、含めないかも検討のひとつであったが、医療プラットフォーム事業にある CLINICS のロゴがシンボルマーク付きであるため、医療プラットフォームに関連するプロダクト = シンボルを定義するというルールを策定しシンボルも設計。シンボルは薬の構造式に利用される「ハニカム構造」をモチーフとし、ブランドカラーと合わせて詳細に作り込んでいった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142603.png",
      "alt": "20210625142603.png"
    }}></img>
    <p>{`また、Pharms の製品紹介用ランディングページやプロダクトデザインのモックアップを作成し、ロゴとのバランスなども考慮しながら調整を行っていった。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142619.png",
      "alt": "20210625142619.png"
    }}></img>
    <p>{`最終的に、患者・調剤薬局・医療機関の 3 者のつながりを三角形で表現しつつ、中心を先述した「ハニカム構造」をモチーフとした形状と、Pharms の頭文字「P」をカプセルと錠剤で表現して、調剤薬局システムとしてシンボルマークに命を吹き込んだ。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210625/20210625142638.png",
      "alt": "20210625142638.png"
    }}></img>
    <h1>{`まとめ`}</h1>
    <p>{`このような過程を経て、Pharms のブランドが完成したのだが、これらは調剤薬局システムの開発としては氷山の一角でしかない。本丸はプロダクトデザイン。一般的にはロゴとプロダクトデザインは別プロジェクトで進行したり、担当するデザイナーが別だったりすることも多いのではないだろうか。`}</p>
    <p>{`Pharms はブランド設計、プロダクトデザイン、マーケティング資材といったデザイン領域をすべて私が担当していくことになるのだが、それ故に事業全体を俯瞰し理解しながらデザインや UI デザインに魂を込めて携わることができた。デザイナーキャリアとしてもここまで幅広く携われたことは非常に貴重な経験を得ることができたと自負している。`}</p>
    <p>{`続いてプロダクトデザイン開発の秘話について語りたいところだが、現在 Pharms 以外の医療プラットフォーム事業に関連する新たなプロダクト開発に注力しているため、その話はまたの機会に。`}</p>
    <hr></hr>
    <p>{`医療プラットフォーム事業に関連するプロダクトをこれからも創出し成長させていく面白い時期にあり、現在デザイナーを積極採用中です。カジュアルに話を聞きたい、医療領域のデザインに興味があるといったデザイナーの方は、ぜひ`}<a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/designer-new.html"
      }}>{`こちら`}</a>{`までご連絡いただけると幸いです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/designer-new.html"
      }}>{`https://www.medley.jp/jobs/designer-new.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      